import { useQuery } from '@tanstack/react-query'

import { QuestionnaireTask } from '@/types/questionnaire'

import { useFetchFromAPI } from './useFetchFromAPI'

export const usePatientQuestionnairesTasks = (patientId: string) => {
  const fetchAPI = useFetchFromAPI()

  const { data: questionnaires, isLoading } = useQuery<QuestionnaireTask[]>({
    queryFn: async () =>
      fetchAPI(`admin/questionnaires?patientId=${patientId}`),
    queryKey: ['patientQuestionnaires', patientId],
  })

  return { isLoading, questionnaires }
}
