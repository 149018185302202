import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import * as S from '@/components/admin/ScreenContainer.styled.ts'
import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Header2 } from '@/components/common/Text.tsx'
import { useCreateFeatureFlag } from '@/hooks/admin/useFeatureFlags.ts'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { ExpandFeatureFlag } from '@/screens/admin/UserManagement/FeatureFlags/ExpandFeatureFlag.tsx'
import { FeatureFlagsTable } from '@/screens/admin/UserManagement/FeatureFlags/FeatureFlagsTable.tsx'
import { useFeatureFlagFormFields } from '@/screens/admin/UserManagement/FeatureFlags/useFeatureFlagFormFields.ts'
import { FeatureFlagFromAPI } from '@/types/featureFlag.ts'

const HeaderBar = styled(S.HeaderBar)`
  background-color: ${({ theme }) => theme.colors.common.white};
  margin: 0 40px;
  padding: 16px 20px;
`

export const FeatureFlags = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.featureFlags',
  })
  const [newFeatureFlagModal, setNewFeatureFlagModal] = useState<boolean>(false)
  const [expandFeatureFlagKey, setExpandFeatureFlagKey] = useState<
    string | null
  >()
  const { featureFlagFormFields } = useFeatureFlagFormFields()
  const { createFeatureFlag, isError: isErrorCreatingFeatureFlag } =
    useCreateFeatureFlag({
      onSuccess: () => {
        setNewFeatureFlagModal(false)
      },
    })

  const handleRowClick = useCallback(
    (item: FeatureFlagFromAPI) => {
      setExpandFeatureFlagKey(item.key)
    },
    [expandFeatureFlagKey, setExpandFeatureFlagKey],
  )

  if (expandFeatureFlagKey) {
    return (
      <ExpandFeatureFlag
        featureFlagKey={expandFeatureFlagKey}
        goBack={() => setExpandFeatureFlagKey(null)}
      />
    )
  }

  return (
    <>
      <HeaderBar>
        <Header2>{t('title')}</Header2>
        <S.StyledActionButtons>
          <StyledActionButton
            onClick={() => {
              setNewFeatureFlagModal(true)
            }}
            variant="Primary"
          >
            {t('actions.add')}
          </StyledActionButton>
        </S.StyledActionButtons>
      </HeaderBar>
      <FeatureFlagsTable onRowClick={handleRowClick} />
      {newFeatureFlagModal && (
        <EditModal
          closeModal={() => setNewFeatureFlagModal(false)}
          errorMessage={t('createModal.error')}
          formFields={featureFlagFormFields}
          isError={isErrorCreatingFeatureFlag}
          onSave={(featureFlag) => {
            createFeatureFlag({
              featureFlag: {
                ...featureFlag,
                enabled: featureFlag.enabled ?? false,
                value: featureFlag.value?.split(',').map((q) => q.trim()),
              },
            })
          }}
          title={t('createModal.title')}
        />
      )}
    </>
  )
}
