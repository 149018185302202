import { useQuery } from '@tanstack/react-query'

import { useAuthentication } from '@/contexts/useAuthenticationContext'
import {
  FeatureFlagKeys,
  useFeatureFlags,
} from '@/hooks/data/useFeatureFlags.tsx'
import { useActiveConversation } from '@/state/hooks/useActiveConversation.ts'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import { ChatMessageAddedEvent } from '~common/serverToClientParser.ts'

interface SuggestedMessage {
  traceId: string
  content: string
  conversationId: string
}

const serviceChatUrl = import.meta.env.API_URL

export const useActiveChatSuggestion = () => {
  const { getFeatureFlagByKey } = useFeatureFlags()

  const { token } = useAuthentication()

  const activeConversation = useActiveConversation()
  const clinician = useCurrentUser()

  const chatAddedEvents = activeConversation?.events.filter(
    (event) => event.type === 'ChatMessageAdded' && event.content,
  ) as ChatMessageAddedEvent[]
  const lastMessage = chatAddedEvents[chatAddedEvents.length - 1]

  const { data, error, isLoading } = useQuery<SuggestedMessage>({
    enabled:
      Boolean(activeConversation) &&
      lastMessage &&
      !lastMessage.isClinician &&
      Boolean(getFeatureFlagByKey(FeatureFlagKeys.ENABLE_CHAT_ASSISTANT)) &&
      activeConversation?.assignedToId === clinician.id,
    queryFn: () =>
      fetch(
        `${serviceChatUrl}/conversations/${activeConversation?.id}/suggestions`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        },
      ).then((res) => res.json()),
    queryKey: ['chat-suggestion', activeConversation?.id, lastMessage?.id],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  return {
    error,
    hasSuggestedMessage: !!data,
    isLoading,
    message: data,
  }
}

export const useSendFeedback = () => {
  const serviceChatUrl = import.meta.env.API_URL
  const { token } = useAuthentication()

  return (
    traceId: string,
    feedback: {
      correction: string
      defects: string[]
      rating: string
    },
  ) =>
    fetch(`${serviceChatUrl}/suggestions/${traceId}/feedback`, {
      body: JSON.stringify(feedback),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
}
