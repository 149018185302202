import { useQuery } from '@tanstack/react-query'

import { ConversationEvent } from '@/types/conversation'

import { useFetchFromAPI } from './useFetchFromAPI'

export const useInternalConversationEvents = (
  internalConversationId: string,
) => {
  const fetchAPI = useFetchFromAPI()

  const { data: events, isLoading } = useQuery<ConversationEvent[]>({
    queryFn: async () =>
      fetchAPI(
        `admin/conversations/internal-conversations/${internalConversationId}/events`,
      ),
    queryKey: ['internalConversationEvents', internalConversationId],
  })

  return { events, isLoading }
}
